import React from "react";
import { useCart } from "../hooks/useCart";

/** BeMum products */
export const products = Object.freeze({
  GENETIC_TEST: "geneticTest",
  ESSENTIAL_COACHING: "essentialCoaching",
  ESSENTIAL_SUPPLEMENT: "essentialSupplement",
  OMEGA_SUPPLEMENT: "omegaSupplement",
  ANTIOX: "antiox",
  EQUILIBRE: "equilibre",
  COACHING: "extraCoaching",
  MOBILE_APP: "mobileApp",
  BEDADDY_SUPPLEMENTS: "bedaddySupplements",
  FNP: "fnp",
});

/** BeMum programs */
export const programs = Object.freeze({
  BEMUM: "bemum",
  BEDADDY: "bedaddy",
  FNP: "fnp",
  SHOP: "shop",
});

export const paymentType = Object.freeze({
  PAYMENT: "payment",
  SUBSCRIPTION: "subscription",
});

/**
 * Version of the cart.
 * Bump this number when publishing a backward incompatible change to the Cart data structure.
 * useCart hook will destroy any cart using a previous version than this.
 *
 * @typedef CartVersion
 * @type {number}
 **/
export const CURRENT_CART_VERSION = 6;

/**
 * Data structure of a BeMum shopping cart
 * @typedef Cart
 * @type {object}
 * @property {CartContents} contents
 * @property {?StripeCoupon} coupon
 * @property {Definition} definition
 * @property {?number} discountAmount - Amount discounted from the cart subtotal.
 * @property {?number} discountPercent - Percent discounted from the cart subtotal.
 * @property {number} subtotal - Sum of each cart items price
 * @property {boolean} freeShipping - Shipping fees or not
 * @property {number} total - Sum of each cart items price, minus a possible discount
 * @property {string} paymentType - Payment type (PAYMENT or SUBSCRIPTION)
 * @property {CartVersion} version
 */

/** Contents of the cart, ie. it's what has been added by the customer.
 * @typedef CartContents
 * @property {Product[]} items - Products that will actually be bought
 * @property {?ProgramName} program - Name of the latest program added to the cart
 */

/**
 * Static, exhaustive definition of what BeMum sells. It is NOT supposed to be mutated at runtime.
 * @typedef Definition
 * @type {object}
 * @property {Object.<string, Product>} availableProducts - Products to be sold
 * @property {Object.<string, Program>} availablePrograms - Programs to be sold
 * @property {number} shippingFees - Price of the shipping
 */

/**
 * Data structure representing a BeMum product
 * @typedef Product
 * @type {object}
 * @property {string} description - Description of the product
 * @property {string} longDescription - Long description of the product
 * @property {?boolean} isLongDescriptionHideable - Should the long description be always shown, or should it be hideable
 * @property {?number} discountedPrice - Price of the product after applying a discount
 * @property {ProductName} id - Identifier of the product
 * @property {string} label - Label (name) of the product
 * @property {number} price - Initial price of the product, without any discount applied on it
 * @property {?string} refund - User-facing info about health insurance refunds
 * @property {string[]} stripe_product_id - The product identifier in Stripe
 */

/**
 * Data structure representing a BeMum program,
 * which is nothing more than a pack of BeMum products
 * @typedef Program
 * @type {object}
 * @property label - Label (Name) of the program
 * @property {ProductName[]} - List of products included in the program
 */

/**
 * Possible names for a BeMum program
 * @typedef ProgramName
 * @type {'essential'}
 */

/**
 * Possible names for a BeMum product
 * @typedef ProductName
 * @type {'geneticTest'|'essentialCoaching'|'essentialSupplement'|'omegaSupplement'|'mobileApp'|'antiox'|'equilibre'|'extraCoaching'}
 */

/**
 * Object definition here {@link https://stripe.com/docs/api/promotion_codes/object Stripe docs}
 * @typedef StripeCoupon
 * @type {object}
 */

/** @type {Cart} */
export const defaultCart = {
  contents: {
    items: [],
    program: null,
  },
  coupon: null,
  definition: {
    availableProducts: {
      [products.GENETIC_TEST]: {
        description: "",
        longDescription:
          "Gene analysis in relation to your diet, carried out by our partner Clinical Enterprise.",
        price: 196,
        id: products.GENETIC_TEST,
        label: "Nutrigenetic testing",
        stripe_product_id: process.env.GATSBY_STRIPE_PROD_GENETIC_TEST,
      },
      [products.ESSENTIAL_SUPPLEMENT]: {
        description: "",
        longDescription:
          "Our science-backed prenatal. An essential for all women preparing to start, or grow, their family.",
        isLongDescriptionHideable: true,
        discountedPrice: null,
        price: 37,
        id: products.ESSENTIAL_SUPPLEMENT,
        label: "Essential",
        stripe_product_id: process.env.GATSBY_STRIPE_PROD_ESSENTIAL_SUPPLEMENT,
      },
      [products.OMEGA_SUPPLEMENT]: {
        description: "",
        longDescription:
          "A DHA and EPA omega 3 fatty acid supplement, made from high quality fish oils. We recommend this to all women trying to conceive.",
        isLongDescriptionHideable: true,
        discountedPrice: null,
        price: 38,
        id: products.OMEGA_SUPPLEMENT,
        label: "Omega",
        stripe_product_id: process.env.GATSBY_STRIPE_PROD_OMEGA_SUPPLEMENT,
      },
      [products.ESSENTIAL_COACHING]: {
        description: "",
        longDescription:
          "Thirty-minute monthly fertility nutrition coaching with your specialist dietitian.",
        isLongDescriptionHideable: false,
        discountedPrice: null,
        price: 115,
        shopPrice: 159,
        id: products.ESSENTIAL_COACHING,
        label: "Monthly dietitian coaching",
        stripe_product_id: process.env.GATSBY_STRIPE_PROD_ESSENTIAL_COACHING,
      },
      [products.MOBILE_APP]: {
        description: "Mobile application",
        longDescription:
          "Access to the mobile application for personalized advice and follow-up",
        isLongDescriptionHideable: true,
        discountedPrice: null,
        price: 80,
        id: products.MOBILE_APP,
        label: "Mobile App",
        stripe_product_id: process.env.GATSBY_STRIPE_PROD_MOBILE_APP,
      },
      [products.ANTIOX]: {
        description:
          "Additional protection against the effects of age or lifestyle-related oxidative stress.Recommended for all women age 35 years or older.",
        discountedPrice: null,
        id: products.ANTIOX,
        label: "P+",
        price: 49,
        stripe_product_id: process.env.GATSBY_STRIPE_PROD_35YO,
      },
      [products.EQUILIBRE]: {
        description:
          "Specifically designed for women with PCOS or those who are overweight (BMI >25), our S+ supplement contains chromium and two types of inositols. These help to regulate blood sugar levels and provide a beneficial effect on the regulation of the ovulatory cycle.",
        discountedPrice: null,
        id: products.EQUILIBRE,
        label: "S+",
        price: 38,
        stripe_product_id: process.env.GATSBY_STRIPE_PROD_WEIGHT,
      },
      [products.COACHING]: {
        description: "",
        longDescription:
          "Upgrade your coaching program with dietitian telehealth coaching every two weeks.",
        isLongDescriptionHideable: false,
        discountedPrice: null,
        id: products.COACHING,
        label: "Coaching+",
        price: 115,
        stripe_product_id: process.env.GATSBY_STRIPE_PROD_COACHING,
      },
      //bedaddy
      [products.BEDADDY_SUPPLEMENTS]: {
        description: "",
        longDescription:
          "The complete four month BeDaddy supplement program, designed to improve sperm quality",
        isLongDescriptionHideable: true,
        discountedPrice: null,
        price: 68,
        shopPrice: 274,
        id: products.BEDADDY_SUPPLEMENTS,
        label: "BeDaddy Supplements",
        stripe_product_id: process.env.GATSBY_STRIPE_PROD_BEDADDY_SUPPLEMENTS,
      },

      [products.FNP]: {
        description: "",
        longDescription:
          "Nutrigenetic test and a one-time consult with a fertility dietitian.",
        isLongDescriptionHideable: false,
        discountedPrice: null,
        id: products.FNP,
        label: "Fertility Nutrition Profile",
        price: 396,
        stripe_product_id: process.env.GATSBY_STRIPE_PROD_FNP,
      },
    },
    availablePrograms: {
      [programs.BEMUM]: {
        id: programs.BEMUM,
        label: "BeMum",
        products: [
          products.ESSENTIAL_COACHING,
          products.ESSENTIAL_SUPPLEMENT,
          products.OMEGA_SUPPLEMENT,
        ],
      },
      [programs.BEDADDY]: {
        id: programs.BEDADDY,
        label: "BeDaddy",
        products: [products.ESSENTIAL_COACHING],
      },
      [programs.FNP]: {
        id: programs.FNP,
        label: "Fertility Nutrition Program",
        products: [products.FNP],
      },
      [programs.SHOP]: {
        id: programs.SHOP,
        label: "E-SHOP",
        products: [],
      },
    },
    shippingFees: 6.5,
  },
  discountAmount: null,
  discountPercent: null,
  subtotal: 0,
  freeShipping: false,
  total: 0,
  version: CURRENT_CART_VERSION,
};

const CartContext = React.createContext({
  cart: defaultCart,
  setCart: () => {},
});

/** Provide a single source of truth for BeMum shopping cart contents. */
function CartProvider({ children }) {
  const [cart, setCart] = useCart();

  return (
    <CartContext.Provider value={{ cart, setCart }}>
      {children}
    </CartContext.Provider>
  );
}

export default CartContext;
export { CartProvider };
